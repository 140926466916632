<template>
  <div class="home-list" style="overflow-y:hidden;">
    <!--标题-->
    <recommend @btnType="queryTradeList" class="home-recommend"></recommend>
    <!--内容-->
    <home-list-item
      v-if="this.homelist != []"
      :homelist="homelist"
      :typeIndex="typeIndex"
      :originPage="'HomeList'"
      style="margin-top:40px"
    ></home-list-item>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import Recommend from './Recommend'
import HomeListItem from './HomeListItem'
import { getUserInfoByToken, getHomeListItem, getTradeList } from 'network/home'
export default {
  name: 'HomeList',
  data() {
    return {
      typeIndex: 0,
      homelist: []
    }
  },
  components: {
    Recommend,
    HomeListItem
  },
  created() {
    this.$store.commit('initTradeListPageIndex')
    const token = this.$route.query.token
    if (token) {
      this.setToken({ token: token })
      this.getUserInfo(token)
    }
    if (this.typeIndex === 0) {
      this.queryTradeList(this.typeIndex, 1)
    }
  },

  methods: {
    ...mapMutations(['setToken']),
    getUserInfo(token) {
      getUserInfoByToken(token).then((res) => {
        if (!res.data.result) {
          this.$message('获取用户信息出错')
        } else if (res.data.result.token != null) {
          localStorage.setItem('userInfo', JSON.stringify(res.data.result))
          localStorage.setItem('userId', res.data.result.user_id)
          this.userlist = res.data.result
        } else {
          this.$message('用户名密码错误')
        }
      })
    },
    /*查询信息*/
    queryTradeList(typeIndex) {
      this.typeIndex = typeIndex
      var pageIndex = this.$store.state.tradeListPageIndex
      getTradeList(this.typeIndex, pageIndex).then((res) => {
        if (pageIndex == 1) {
          this.homelist = []
        }
        this.homelist = this.homelist.concat(res.data.result.trade_list)
      })
    },
    /*获取所有数据*/
    getHomeListItem() {
      getHomeListItem().then((res) => {
        this.homelist = res.data.result.trade_list
      })
    }
  }
}
</script>

<style scoped>
.home-recommend {
  position: sticky;
  top: 38px;
}
</style>
