<template>
  <div
    v-if="recommends.length > 0"
    class="recommend"
    style="width:100%;z-index: 9;padding:10px 10px 10px 10px;position:fixed;overflow: scroll;"
  >
    <div v-for="(item, index) in recommends" :key="item.id" @click="btnType(index)">
      <span
        :class="$store.state.typeIndex === index ? 'active' : 'noActive'"
        style="padding: 5px 15px 5px 15px;border-radius: 20px;"
        >{{ item.name }}</span
      >
    </div>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </div>
</template>

<script>
import { getType } from 'network/home'
export default {
  name: 'Recommend',
  data() {
    return {
      recommends: [{ id: null, name: '推荐' }],
      typeIndex: 0
    }
  },
  created() {
    this.getType()
  },
  methods: {
    /*获取类型数据*/
    getType() {
      getType().then((res) => {
        if (res.data.status == 1) {
          this.recommends = this.recommends.concat(res.data.result)
          this.$store.commit('setTypeListLenght', this.recommends.length)
        }
      })
    },
    btnType(index) {
      this.$store.commit('setTypeIndex', index ? index : 0)
      this.$store.commit('initTradeListPageIndex')
      this.$emit('btnType', index)
    }
  }
}
</script>

<style scoped>
.recommend {
  height: 30px;
  background-color: #fff;
  display: flex;
  font-size: 14px;
  color: rgb(150, 150, 150);
  align-items: center;
  /*强制不换行*/
  white-space: nowrap;
  /*超出部分可滑动*/
  overflow-x: auto;
}
/*滚动条隐藏*/
.recommend::-webkit-scrollbar {
  display: none;
}
.recommend div {
  margin: 0 10px;
}

.active {
  color: #000;
  background-color: #f6f6f6;
  border: 1px solid #dddddd;
}

.noActive {
  background-color: #fbfbfb;
}
</style>
